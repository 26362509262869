import 'react-phone-number-input/style.css'
import React, {useState, useEffect, useRef} from 'react'
import { Container, Form, Button, Alert, Modal} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { showToast, uri } from '../utils';
import PhoneInput, {isValidPhoneNumber, getCountryCallingCode} from 'react-phone-number-input'

const Details = () => {  
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [cityid, setCityid] = useState('');
    const [statusx, setStatusx] = useState(null);
    const [cities, setCities] = useState([]);
    const [emergencyPhone, setEmergencyPhone] = useState('');
    const [emergencyName, setEmergencyName] = useState('');
    const [banks, setBanks] = useState([]);
    const [moneyTransfers, setMoneyTransfers] = useState([]);
    const [useBank, setUseBank] = useState(false);
    const [modal, setModal] = useState(false);
    const [useMoneyTransfer, setUseMoneyTransfer] = useState(false);
    const [bankid, setBankId] = useState('');
    const [accountType, setAccountType] = useState('checking');
    const [accountNumber, setAccountNumber] = useState('');
    const [mtid, setMtid] = useState('')
    const [mtaccountNumber, setMtAccountNumber] = useState('');
    const [dob, setDob] = useState('');
    const application_status = useRef(0);
    const navigate = useNavigate();

    const save = async (e) => {
      try {
        e.preventDefault();
        if(!firstName.trim() || !lastName.trim() || !phone ||  !phone.trim()  || (!useBank && !useMoneyTransfer) || !dob) return;
        if(error) setError(null)
        setLoading(true);
        const req = await fetch(uri('/submitdata'), {
          method: 'POST',
          body: JSON.stringify({
            first_name:firstName, 
            last_name: lastName, 
            phone_number: phone, 
            emergency_phone: emergencyPhone ? emergencyPhone : null,
            bankid: useBank ? bankid : null,
            useBank, 
            useMoneyTransfer, 
            account_number: useBank ? accountNumber:null, 
            account_type: useBank ? accountType : null, 
            mtnumber: useMoneyTransfer ? mtaccountNumber : null, 
            mtid: useMoneyTransfer ? mtid : null, 
            dob,
            emergency_name: emergencyName,
            cityid: cityid ? cityid : null
          }),
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`}
        });
        const res = await req.json();
        if(req.status === 200){
            showToast('Profile Saved', 'success');
            navigate("/profile", {replace:true});
        } else if(req.status === 498) {
          localStorage.removeItem("token");
          navigate('/login', {replace: true});
        } else if(req.status === 401) {
        //localStorage.removeItem("token");
        navigate('/otp', {replace: true});
        } else{
            setError(res.message);
            setLoading(false);
        }
      } catch(err){
        console.log(err);
        setError("Server Error");
        setLoading(false);
      }
      
    }

    const getRegistrationData = async () => {
        try{
            const req = await fetch(uri('/registrationdata'), {
                method: 'GET',
                headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
            });
            const res = await req.json();
            if(req.status === 200){
                console.log(res);
                application_status.current = res.application_status;
                setBanks(res.banks);
                setMoneyTransfers(res.moneytransfers)
                setMtid(res.moneytransfers[0].id)
                //setData(res.data);
                setCities(res.cities)
                setStatusx(res.data.statusx)
                // const city_id = res.data.find(i => i.key == 'cityid');
                // if(city_id && city_id.value) setCityid(city_id.value)
                // else if(res.cities.length > 0) setCityid(res.cities[0].id)

                if(res.data.first_name) setFirstName(res.data.first_name)
                if(res.data.last_name) setLastName(res.data.last_name)
                if(res.data.phone_number) setPhone(res.data.phone_number)
                if(res.data.emergency_phone) setEmergencyPhone(res.data.emergency_phone)
                if(res.data.emergency_name) setEmergencyName(res.data.emergency_name)
                if(res.data.dob) setDob(res.data.dob)
                if(res.data.bankid) setUseBank(true);
                if(res.data.mtid) setUseMoneyTransfer(true);
                if(res.data.bankid) setBankId(res.data.bankid)
                else setBankId(res.banks[0].id)
                if(res.data.cityid) setCityid(res.data.cityid)
                else setCityid(res.cities[0].id)
                if(res.data.account_type) setAccountType(res.data.account_type)
                if(res.data.account_number) setAccountNumber(res.data.account_number)
                if(res.data.mtid) setMtid(res.data.mtid)
                if(res.data.mt_number) setMtAccountNumber(res.data.mt_number)

                setLoading(false);
                //console.log(application_status.current)
            } else if(req.status === 401) {
              //localStorage.removeItem("token");
              navigate('/otp', {replace: true});
            } else if(req.status === 498) {
                localStorage.removeItem("token");
                navigate('/login', {replace: true});
            } else {
                setError(res.message);
                setLoading(false);
            }
        } catch(err) { 
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }
    }
    const Testing = async () =>{
      console.log('Inisde Testing')
    }
    useEffect(() => {
      console.log("Git Testing");

        getRegistrationData();
    },[])


  if(loading) return <Spinner />
  return (
    <Container>

        <Modal
            show={modal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={e => setModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to save your changes?</p>
            </Modal.Body>
        <Modal.Footer>
            <Button variant='danger' onClick={() => setModal(false)}>Close</Button>
            <Button onClick={save}>Confirm</Button>
      </Modal.Footer>
    </Modal>


        <h1 className='mt-4'>Personal Information</h1>
        {error && <Alert variant='danger' key='danger'>{error}</Alert>}
        <Form onSubmit={(e) => {
          e.preventDefault();
          setModal(true);
        }}>
        
        <Form.Group className='mt-4'>
          <Form.Label>First Name/الاسم الأول</Form.Label>
          <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} required type="text"  />
          {statusx.first_name_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.first_name_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Last Name/اسم العائلة</Form.Label>
          <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)} required type="text"  />
          {statusx.last_name_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.last_name_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>City/المدينة</Form.Label>
          <Form.Select onChange={e => setCityid(e.target.value)} value={cityid}>
            {cities.map((city) => <option key={city.id} disabled={city.status == 2} value={city.id}>{city.name}</option>)}
          </Form.Select>
          {statusx.cityid_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.cityid_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Phone Number/رقم الهاتف</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone ? phone : ''}
            defaultCountry='LB'
            initialValueFormat='national'
            className='form-control'
            onChange={setPhone}
          />
          {statusx.phone_number_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.phone_number_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Emergency Phone Number/رقم الهاتف للطوارئ</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={emergencyPhone}
            defaultCountry='LB'
            className='form-control'
            onChange={setEmergencyPhone}
          />
          {statusx.emergency_phone_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.emergency_phone_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Emergency Person Name/ اسم شخص الطوارئ</Form.Label>
          <Form.Control value={emergencyName} onChange={(e) => setEmergencyName(e.target.value)} required type="text"  />
          {statusx.emergency_name_conf == 2 &&
          <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.emergency_name_reason}</Form.Control.Feedback>
          }
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Choose Payment methods/اختر طريقة الدفع</Form.Label>
        </Form.Group>

        <ul>
          <li style={{listStyle:'none'}}><input type='checkbox' checked={useBank} onChange={(e) => setUseBank(e.target.checked)} style={{marginRight: 5}} />Bank</li>
          {useBank && 
          <li style={{listStyle:'none'}}>
                <Form.Group className='mt-3'>
                    <Form.Label>Choose Bank/اختر البنك</Form.Label>
                    <Form.Select onChange={(e)=>setBankId(e.target.value)} required={useBank} value={bankid}>
                        {banks.map((bank) => <option key={bank.id} value={bank.id}>{bank.name}</option>)}
                    </Form.Select>
                    {statusx.bankid_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.bankid_reason}</Form.Control.Feedback>
                    }
                </Form.Group>

                <Form.Group className='mt-3'>
                    <Form.Label>Account type/نوع الحساب</Form.Label>
                    <Form.Select required={useBank} value={accountType} onChange={e => setAccountType(e.target.value)}>
                        <option value={'checking'}>Checking/حساب جاري</option>
                        <option value={'recurring'}>Recurring/حساب متكرر</option>
                        <option value={'savings'}>Savings/حساب التوفير</option>
                    </Form.Select>
                    {statusx.account_type_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.account_type_reason}</Form.Control.Feedback>
                    }
                </Form.Group>

                <Form.Group className='mt-3'>
                    <Form.Label>Account number/رقم الحساب</Form.Label>
                    <Form.Control required={useBank} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} type="text"  />
                    {statusx.account_number_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.account_number_reason}</Form.Control.Feedback>
                    }
                </Form.Group>

                <hr />
          </li> 
          }
          <li style={{listStyle:'none'}}><input type='checkbox' checked={useMoneyTransfer} style={{marginRight: 5}} onChange={(e) => setUseMoneyTransfer(e.target.checked)} />Money Transfer</li>
          {useMoneyTransfer && 
          <li style={{listStyle:'none'}}>
                <Form.Group className='mt-3'>
                    <Form.Label>Choose Money Transfer/اختر نقل الأموال</Form.Label>
                    <Form.Select onChange={(e) => setMtid(e.target.value)}   required={useMoneyTransfer}>
                        {moneyTransfers.map((mt) => <option key={mt.id} value={mt.id}>{mt.name}</option>)}
                    </Form.Select>
                    {statusx.mtid_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.mtid_reason}</Form.Control.Feedback>
                    }
                </Form.Group>

                <Form.Group className='mt-3'>
                    <Form.Label>Account number/رقم الحساب</Form.Label>
                    <Form.Control required={useMoneyTransfer} value={mtaccountNumber} onChange={(e) => setMtAccountNumber(e.target.value)} type="text"  />
                    {statusx.mt_number_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.mt_number_reason}</Form.Control.Feedback>
                    }
                </Form.Group>
          </li>
          }

        </ul>

        <Form.Group className='mt-3'>
          <Form.Label>Date of birth/تاريخ الميلاد</Form.Label>
          <Form.Control value={dob} onChange={(e) => setDob(e.target.value)} required type="date"  />
                    {statusx.dob_conf == 2 &&
                      <Form.Control.Feedback style={{display: 'block'}}  type='invalid'>{statusx.dob_reason}</Form.Control.Feedback>
                    }
        </Form.Group>
        {/* (useBank && (!bankid || !accountType || !accountNumber)) || (useMoneyTransfer && (!mtid || !mtaccountNumber)) ||  */}
        <Button disabled={(application_status.current != null &&  application_status.current != 2 && application_status.current != 3) || !firstName.trim() || !lastName.trim() || !phone || !phone.trim() || (!useBank && !useMoneyTransfer) || !dob || !cityid} style={{width:'100%',backgroundColor:'#e05b0e',border:'none',marginTop:'2%',marginBottom:'3%'}} type='submit'>Submit</Button>

      </Form>

    </Container>
  )
}

export default Details