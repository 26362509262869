import {toast} from "react-toastify"

export const uri = (path) => {
    if(process.env.NODE_ENV == 'production') return `https://api.trakitrak.com/shoppers${path}`;
    else return `https://api.trakitrak.com/shoppers${path}`;
}

export const showToast = (content, type) => {
    toast(content, {
        type: type,
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover:false,
        draggable:false,
        progress: undefined,
        theme:'light'
    })
}