import React, {useState} from 'react'
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { uri } from '../utils';
import { GoogleLogin } from '@react-oauth/google';
import logo from "../shopperr.png";
import AppleSignin from 'react-apple-signin-auth';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [recptcha, setIsrecptcha] = useState(null);

    const navigate = useNavigate();

    const login = async (e) => {
        try{
            e.preventDefault();
            if(!email.trim() || !password.trim()) return;
            if(error) setError(null)
            setLoading(true);
            const req = await fetch(uri('/login'), {
                method: 'POST',
                body: JSON.stringify({email, password}),
                headers: {'Content-Type': 'application/json'}
            });
            const res = await req.json();
            if(req.status === 200){
                localStorage.setItem("token", res.access_token);
                navigate("/profile", {replace:true});
                setIsrecptcha(null)
            } else if(req.status == 401){
              localStorage.setItem("token", res.access_token);
              navigate('/otp')
              setIsrecptcha(null)

            } else {
                setError(res.message);
                setLoading(false);
                setIsrecptcha(null)

            }
        } catch(err){
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }
    }

    const googleSignin = async (credentials) => {
      try{
          if(error) setError(null);
          setLoading(true);
          const req = await fetch(uri('/sociallogin'), {
              method: 'POST',
              body: JSON.stringify({credentials, type: 1,is_google:true,is_apple:false}),
              headers: {'Content-Type': 'application/json'}
          });
          const res = await req.json();
          if(req.status === 200){
              localStorage.setItem("token", res.access_token);
              navigate("/profile", {replace:true});
          } else {
              setError(res.message);
              setLoading(false);
          }
      } catch(err){
        console.log(err);
        setError("Server Error");
        setLoading(false);
      }
    }
    const AppleSignIn = async (id_token) => {
      try{
          if(error) setError(null);
          setLoading(true);
          const req = await fetch(uri('/sociallogin'), {
              method: 'POST',
              body: JSON.stringify({id_token, type: 1,is_google:false,is_apple:true}),
              headers: {'Content-Type': 'application/json'}
          });
          const res = await req.json();
          if(req.status === 200){
              localStorage.setItem("token", res.access_token);
              navigate("/profile", {replace:true});
          } else {
              setError(res.message);
              setLoading(false);
          }
      } catch(err){
        console.log(err);
        setError("Server Error");
        setLoading(false);
      }
    }
  if(loading) return <Spinner />
  return (
    <>
      {error && <Alert variant='danger' key='danger'>{error}</Alert>}
    <div className='login'>  
    <div  className='first-div'>
    <img src={logo} alt='trakitrak shoppers logo'  style={{height:'15%',width:'40%', objectFit:'contain' }} />
    <div style={{ width: '99%', height: 3, background: 'linear-gradient(to right, #ffffff, #e05b0e)', alignSelf: 'center',marginTop:'2%'}}></div>
    <div style={{ width: '99%', height: 250, alignSelf: 'center',marginTop:'2%'}}>
    <img src={'./login.jpg'}  style={{height:'100%',width:'100%', objectFit:'cover' }} />

    </div>

  </div>
      <div className='login-small'>
      <div className="card" style={{width:'100%',height:400,border:'none',display:'flex',justifyContent:'center'}}>
        {/* <div className="card-header">Login</div> */}

        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
            <div className="mt-3s logindivv">
                <label className='name'>Email</label>
                <input  onChange={(e) => setEmail(e.target.value)} className="form-control1 w-100 name" type="email" />
            </div>

            <div className="mt-3 logindivv">
                <label className='name'>Password</label>
                <input   onChange={(e) => setPassword(e.target.value)} className="form-control1 w-100 name" type="password" />
            </div>
            <div className='logindivv'style={{marginTop:10}}>
            <a style={{textDecoration:'underline',fontSize:13,color:'black',alignSelf:'flex-start',width:'75%',}} href="/reset-password" >Reset password</a>
            </div>
            <div style={{paddingTop:10}}>
                <ReCAPTCHA
                sitekey="6LefKnsqAAAAAOop0UL1020jcGRnDW6DW4JP7ERe"
                onChange={(e)=>{setIsrecptcha(e)}}
                />
           </div>
            <div className='logindivv' style={{display:'flex',marginTop:15,alignSelf:'center',alignItems:'center',justifyContent:'space-between'}}>
            <Button disabled={!recptcha} variant='primary' style={{width:'100%'}} onClick={login}>Login</Button>
            </div>
            <div className='logindivv'style={{marginTop:10}}>
            <p style={{color:'black',alignSelf:'flex-start',width:'75%',fontSize:14}}>Log in with:</p>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div style={{marginRight:10,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
          <GoogleLogin
             shape='circle'
             theme='outline'
             type='icon'
             size='large'

             onSuccess={credentialResponse => {
               console.log(credentialResponse);
               googleSignin(credentialResponse.credential);
             }}
             onError={() => {
               console.log('Login Failed');
             }}
           />
           <p style={{margin:0,marginLeft:5,fontSize:14}}>Google</p>
          </div>
           <AppleSignin
          
    /** Auth options passed to AppleID.auth.init() */
    authOptions={{
      /** Client ID - eg: 'com.example.com' */
      clientId: 'co.trakitrak.drivertrakitrak.client',
      /** Requested scopes, seperated by spaces - eg: 'email name' */
      scope: 'email name',
      /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
      redirectURI: 'https://shoppers.trakitrak.com/login',
      /** State string that is returned with the apple response */
      state: 'state',
      /** Nonce */
      nonce: 'nonce',
      /** Uses popup auth instead of redirection */
      usePopup: true,
    }} 
    // REQUIRED
    /** General props */
    uiType="dark"
    /** className */
    className="apple-auth-btn"
    /** Removes default style tag */
    noDefaultStyle={false}
    /** Allows to change the button's children, eg: for changing the button text */
    buttonExtraChildren="Continue with Apple"
    /** Extra controlling props */
    /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
    onSuccess={(response) => {
      console.log({response})
      AppleSignIn(response.authorization.id_token)
    }} // default = undefined
    /** Called upon signin error */
    onError={(error) => console.error(error)} // default = undefined
    /** Skips loading the apple script if true */
    skipScript={false} // default = undefined
    /** Apple image props */
    iconProp={{ style: { marginTop: '10px' } }} // default = undefined
    /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
   // render={(props) => <button {...props}>My Custom Button</button>}
  />
         </div> 
       
            </div>
<div className='logindivv' style={{height:1,backgroundColor:'#d1d1d1',marginTop:15}}></div>
           
           <div className="mt-3 text-center">
        <p>Don't have an account? <Link style={{color:'#e05b0e',textDecoration:'underline'}} to={'/preregister'}>Register</Link></p>
      </div>
            {/* <a style={{textDecoration:'underline',color:'black'}} href="/reset-password" className="text-center mt-3 mb-4">Forgot password?</a> */}
        </div>

    </div>
      </div>
    </div>
 </>
    // <Container className="mt-5">
    //   <h2 className="text-center">Trakitrak Shopper</h2>
    //   {error && <Alert variant='danger' key='danger'>{error}</Alert>}
    //   <Form onSubmit={login}>
        
    //     <Form.Group>
    //       <Form.Label>Email</Form.Label>
    //       <Form.Control onChange={(e) => setEmail(e.target.value)} required type="email"  />
    //     </Form.Group>

    //     <Form.Group className='mt-3'>
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control  onChange={(e) => setPassword(e.target.value)} required type="password"  />
    //     </Form.Group>

    //     <Button disabled={!email.trim() || !password.trim()} variant="primary" type="submit" className='mt-4 tt-button'>
    //       Login
    //     </Button>

    //     <div style={{marginTop: 25}}>
    //       <p>Or</p>
    //       <GoogleLogin
    //         shape='circle'
    //         theme='filled_blue'
    //         type='standard'
    //         size='large'
    //         width={300}
    //         onSuccess={credentialResponse => {
    //           //console.log(credentialResponse);
    //           googleSignin(credentialResponse.credential);
    //         }}
    //         onError={() => {
    //           console.log('Login Failed');
    //         }}
    //       />
    //     </div>

    //   </Form>

    //   <div className="mt-3 text-center">
    //     <p>Don't have an account? <Link to={'/register'}>Register</Link></p>
    //   </div>
    // </Container>
  )
}

export default Login