import React from "react";
import { Navigate } from "react-router-dom";
import { hasJWT } from "./AuthedRoute";

function ProtectedRoute({ component: Component, ...params }) {

  if (hasJWT())
    return (
        <Component {...params} />
    );
  else return <Navigate to={"/login"} replace />;
}

export default ProtectedRoute;
