import React from 'react'
import { Spinner as Spinnerx, Container } from 'react-bootstrap'

const Spinner = () => {
  return (
    <Container className='d-flex justify-content-center align-items-center vh-100'>
        <Spinnerx animation='border' style={{color: '#e05b0e'}}  />
    </Container>
  )
}

export default Spinner