import React from 'react'
import rightIcon from "../next.png"
import Xmark from "../x-mark.png";
import Correct from "../accept.png";
import { useNavigate } from 'react-router-dom'


const ProfileItem = ({item, application_status, conf, label, keyx}) => {
  //const upload = item.upload;
  const navigate = useNavigate();
 
  return (
    <div  
   // onClick={()=>console.log(item)}

    onClick={()=>{
      if(application_status == 0){
      }else{
        navigate(`/profile/${keyx}`)
      }
    }} 
    
    className='mb-4 border p-3 rounded profile-item' style={{display: 'flex',opacity:application_status == 0?0.5:1, justifyContent:'space-between', alignItems:'center'}}>
      <div style={{ display: 'flex', flexDirection: 'column', }}>
  <h6 className='m-0'>{label}</h6>
  {item && conf == 1 ? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Approved</p>:
item && conf == 2 ? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Rejected</p>:
item!=null? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Pending</p>:
null}
 
</div>
        <div style={{display:'flex',alignItems:'center'}}>
          {item && (conf == 1||conf == 2)? null:  
                    <p style={{margin:0,color:application_status == 0?'gray':item!=null?'green':'red'}}>{application_status == 0 ?'In Review':item!=null?'Completed':'Incomplete'}</p>
}
           {item && conf == 1 ?
                       <img alt='right-arrow-icon' src={item && conf == 1 ? Correct : item && conf == 2 ? Xmark : rightIcon} style={{width: 20, height:20}}></img>
:
item && conf == 2?
<img alt='right-arrow-icon' src={item && conf == 1 ? Correct : item && conf == 2 ? Xmark : rightIcon} style={{width: 20, height:20}}></img>

:
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"><title>Chevron right small</title><path d="m16.9 12-4.6 6H8.5l4.6-6-4.6-6h3.8l4.6 6Z" fill="currentColor"></path></svg>

                      }
        </div>
     
    </div>
   
  )
}

export default ProfileItem