import React ,{useState,useEffect} from 'react'
import { Alert, Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import logo from "../shopperr.png";

export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false)
    };
    const isFormValid = () => {
        let x=[]

              if (!email) x.push('Email is required');           
            
              // Validate email
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(email)) x.push('Invalid email');  
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
            const handleSubmit = async() => {
              setErrors([])
      
        
              if (isFormValid()) {
                try {
                
                
                  const req = await fetch('https://api.trakitrak.com/api/resetpassword', {
                    method:'POST',
                                   headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                             },
                    body: JSON.stringify({
                      email:email,
                      
                   }) 
                  })
                  
              if(req.status==200){
                const json = await req.json()
                setError(false)
                setEmail('')
                setShow(true)
                // twoOptionsAlertFunctionxx()
                console.log({json})
              } 
                else{
              
              
                  const json = await req.json()
                  console.log({json})     
              }} catch (error) {
              
                  if (error.name === 'AbortError') {
                    console.log('Fetch aborted')
                  } else {
                    //console.log(err)
                  }
                  }
                  setError(false)
                  setEmail('')
                  setShow(true)
               
             
              } else {
                  setError(true)
      
              }
            };
  return (
    <>
       <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Thank you!</Modal.Title>
        </Modal.Header>
        <Modal.Body>We have sent you an email with further instructions. Please check your inbox and follow the steps provided to complete the process.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
     
             {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}

    <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <img src={logo} alt='trakitrak shoppers logo'  style={{height:55,width:370, objectFit:'contain',marginTop:'5%',marginBottom:'1%' }} />
              <div style={{width:'100%',marginBottom:'5%',paddingLeft:'3%',alignSelf:'center',flexDirection:'column',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e05b0e'}}>  
            <h1 style={{color:'white',fontFamily: 'Poppins',paddingBottom:15,paddingTop:15}}>Reset your password</h1>
     <div style={{zIndex:10,padding:16,marginBottom:-16,color:'rgb(25, 25, 25)',backgroundColor:'white',borderRadius:8,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px'}}>
        <div>
            <p>
            Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>
            <div>
               <input placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#f1f1f1',width:'100%',marginTop:'4%',height:35,borderRadius:8}} />
           
               <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button  onClick={handleSubmit} style={{ borderRadius: 20, marginTop: '2%',backgroundColor:'#e05b0e',border:'none'  }}>
                  Reset Password
                </Button>
                </div>
                <h6>Already on TrakiTrak? <a href='/login' style={{color:'#e05b0e'}}>Login</a></h6>

            </div>
        </div>
     </div>
         </div>
    </div>
    </>
  )
}

