import React, {useCallback, useState, useEffect} from 'react'
import { Container, Button, Alert, Modal} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useParams, useNavigate } from 'react-router-dom';
import { showToast, uri } from '../utils';
import PdfIcon from "../pdf.png"
import Xmark from "../x-mark.png"
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const files_fields = [
    {key: 'govid_front', label: 'Government ID (front)/بطاقة الهوية الوطنية-أمامية'}, 
    {key: 'govid_back', label: 'Government ID (back)/بطاقة الهوية الوطنية-خلفية'}, 
    {key: 'img', label: 'Shopper photo/صورة شخصية'}, 
    {key:  'commercial_driver_license_front', label: 'Commercial driver license (front)/رخصة القيادة التجارية-أمامية'}, 
    {key: 'commercial_driver_license_back', label: 'Commercial driver license (back)/رخصة القيادة التجارية-خلفية'}, 
    {key: 'judicial_record', label: 'Judicial record/سجل عدلي'}, 
    {key: 'car_permit', label: 'Car Permit/وكالة قيادة السيارة'}, 
    {key: 'car_registration_front', label: 'Car registration (front)/رخصة السيارة - أمامية'}, 
    {key: 'car_registration_back', label: 'Car registration (back)/رخصة السيارة - خلفية'}, 
    {key: 'compulsory_insurance', label: 'Compulsory insurance/تأمين الزامي'}
  ];


const Upload = () => {

    const {key} = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [uploaded, setUploaded] = useState(null);
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [modal, setModal] = useState({delete: false, show: false})
    const [error, setError] = useState(null);


    const onDrop = useCallback((acceptedFiles) => {
        if(acceptedFiles.length > 0) {
            const size = acceptedFiles[0].size / (1024 * 1024);
            if(size > 4) return toast.error('Max file size is 4 MB', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "dark",
                });
            setFile(acceptedFiles[0]);
        }
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop, accept: {
        'image/png': ['.png', '.jpg', '.jpeg', '.webp'],
        'text/html': ['.pdf']
    }});

    const getDocument = async () => {
        try{
            const req = await fetch(uri('/document'), {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`},
                body: JSON.stringify({key}),
            });
            const res = await req.json();
            if(req.status === 200){
                //console.log(res);
                setName(files_fields.find(x => x.key == key).label)
                // setName(`${res.document.name}${res.document.namear ? '/' + res.document.namear : ''}`)
                if(res.document) setUploaded(res.document);
                setApplicationStatus(res.application_status);
                setLoading(false)
            } else if(req.status === 401) {
                //localStorage.removeItem("token");
                navigate('/otp', {replace: true});
            } else if(req.status === 498) {
                localStorage.removeItem("token");
                navigate('/login', {replace: true});
            } else {
                setError(res.message);
                setLoading(false);
            }
        } catch(err) { 
            setError("Server Error");
            setLoading(false);
        }
    }

    const uploadFile = async () => {
        try{
            if(!file) return;
            // if(error) setError(null);
            setLoading(true);
            const data = new FormData();
            data.append("key", `${key}`)
            data.append('file', file)
            const req = await fetch(uri('/upload'), {
                method: 'POST',
                headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
                body: data,
            });
            const res = await req.json();
            if(req.status === 200){
                showToast('Upload successful', 'success');
                navigate("/profile", {replace:true});
            } else if(req.status === 401) {
                //localStorage.removeItem("token");
                navigate('/otp', {replace: true});
            } else if(req.status === 498) {
                localStorage.removeItem("token");
                navigate('/login', {replace: true});
            } else {
                setModal({delete: false, show: false});
                setError(res.message);
                setLoading(false);
            }
        } catch(err) { 
            console.log(err);
            setModal({delete: false, show: false});
            setError("Server Error");
            setLoading(false);
        }
    }

    const deleteFile = async () => {
        try{
            setLoading(true);
            const req = await fetch(uri('/deletefile'), {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`},
                body: JSON.stringify({key}),
            });
            const res = await req.json();
            if(req.status === 200){
                showToast('Deleted', 'success');
                navigate("/profile", {replace:true});
            } else if(req.status === 401) {
                //localStorage.removeItem("token");
                navigate('/otp', {replace: true});
            } else if(req.status === 498) {
                localStorage.removeItem("token");
                navigate('/login', {replace: true});
            } else {
                setModal({delete: false, show: false});
                setError(res.message);
                setLoading(false);
            }
        } catch(err) { 
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }   
    }
    // const data = [
    //     {
    //         name:'Government ID (front)',
    //         text:'Make sure all information is readable, not blurry and that all corners of the document are visible. If you are uploading a PDF, insert all pages together in one file.',
    //         image:''
    //     }
    // ]
    useEffect(() => {
        getDocument();
    },[])

  if(loading) return <Spinner />
  return (
    <div style={{backgroundColor: `rgb(238, 238, 238)`,flexDirection:'column',width:'100%',alignItems:'center',height:700,display:'flex',alignSelf:'center'}}>
    <div className='upload'>

                        <div style={{width:'100%',backgroundColor:'black',height:50,display:'flex',alignItems:'center'}}>
                        <svg onClick={()=>navigate('/profile')} width="22px" height="22px" viewBox="0 0 24 24" stroke='white' fill="white" style={{marginLeft:'2%',cursor:'pointer'}}><title>Arrow left</title><path d="M22 13.5H6.3l5.5 7.5H8.3l-6.5-9 6.5-9h3.5l-5.5 7.5H22v3Z" fill="white"></path></svg>
                        <p className='headerr'>TrakiTrak</p>
                        <a onClick={()=>{window.Intercom('show')}}  style={{backgroundColor:'white',textDecoration:'none',borderRadius:25,cursor:'pointer',height:35,width:50,display:'flex',alignItems:'center',justifyContent:'center'}}><p style={{color:'black',paddingTop:5,paddingBottom:5,paddingLeft:20,paddingRight:20,fontSize:16,fontWeight:'500',margin:0,marginLeft:'3%',fontFamily:'Poppins'}}>Help</p></a>
                        </div>

                        <Modal
                            show={modal.show}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={e => setModal({show: false, delete: false})}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Confirm
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to {modal.delete ? 'delete' : 'save'} this file?</p>
                            </Modal.Body>
                        <Modal.Footer>
                            <Button variant='danger' onClick={() => setModal({delete: false, show: false})}>Close</Button>
                            <Button onClick={modal.delete ? deleteFile : uploadFile}>Confirm</Button>
                    </Modal.Footer>
                            </Modal>

<div style={{display:'flex',alignItems:'center',marginLeft:'5%',}}>
    {!file && uploaded && uploaded.statusx[`${key}_conf`] == 2? <svg style={{height:28,minWidth:28,marginTop:15,marginRight:5}} viewBox="0 0 24 24" fill="none" ><title>Alert</title><path d="M12 1.5 1 21h22L12 1.5ZM10.5 8h3v5h-3V8ZM12 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z" fill="red"></path></svg>
:null}
                    <h3 style={{color:!file && uploaded && uploaded.statusx[`${key}_conf`] == 2?'red':'black'}} className='mt-4'>{name}</h3>
                    
</div>
                   {!file && uploaded && uploaded.statusx[`${key}_conf`] == 2&&(<p style={{marginLeft:'5%',fontWeight:'700',color:'black',fontSize:28}}> {uploaded.statusx[`${key}_reason`]}</p>)}
                    <p style={{marginLeft:'5%',fontSize:14}}>{name=='Government ID (front)/بطاقة الهوية الوطنية-أمامية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
                    name=='Government ID (back)/بطاقة الهوية الوطنية-خلفية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
                    name=='Shopper photo/صورة شخصية'?`Your profile picture assists in facilitating recognition. Please be aware that once you submit your profile picture, it undergoes our scrutiny. Upon approval, our department will contact you for an in-person session at our office, where an official trakitrak shirt will be provided for the new photo. Ensure direct eye contact with the camera, with both eyes and mouth clearly visible.`:
name=='Commercial driver license (front)/رخصة القيادة التجارية-أمامية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
name=='Commercial driver license (back)/رخصة القيادة التجارية-خلفية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
name=='Judicial record/سجل عدلي'?'Please make sure we can easily read all the details.':
name=='Compulsory insurance/تأمين الزامي'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
name=='Car registration (front)/رخصة السيارة - أمامية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.':
name=='Car registration (back)/رخصة السيارة - خلفية'?'Make sure all information is readable, not blurry and that all corners of the document are visible.'
:'Make sure all information is readable, not blurry and that all corners of the document are visible.'}</p>

                    {error && <Alert className='mt-3' variant='danger'>{error}</Alert>}
                    {/* {!file && uploaded && uploaded.status == 2 && 
                        <Alert variant="warning">
                        <p>
                            {uploaded.reason}
                        </p>
                        </Alert>
                    } */}
                    {!file && uploaded && uploaded[key] ?
                    <div style={{width:'100%'}}>
                        <div className='d-flex' style={{width:'100%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}} >
                            <div style={{width:'100%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
                                {uploaded[key].slice((uploaded[key].lastIndexOf(".") - 1 >>> 0) + 2) == 'pdf' ? 
                                <img src={PdfIcon} alt='pdf-icon' style={{width: 80, height: 80, objectFit:'contain'}} />
                                : 
                                <img src={uploaded[key]} alt='user-upload' style={{width: 200, height: 200, objectFit:'contain'}}   /> }
                            </div>
                        </div>
                        <div style={{width:'100%',height:50,marginTop:'5%',backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center',boxSizing:'border-box',boxShadow:'0px 4px 16px rgba(0,0,0,0.1)'}}>
                        {uploaded && !uploaded[key] ?
                        <Button disabled={!file || (applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3))} onClick={()=> setModal({delete: false, show: true})} style={{width:'90%',height:40,backgroundColor:'black',border:'none'}} >Submit</Button> 
                        :
                        <Button disabled={applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3)} variant='danger' onClick={()=> setModal({delete: true, show: true})} style={{width:'90%',height:40,backgroundColor:'black',border:'none'}}>Delete your upload</Button>
                        }
                        </div>
                        </div>
                    : 
                    !file ?
                    <div style={{width:'100%'}}>
               
                    <div {...getRootProps({className: 'dropzone'})}>
                    <div style={{width:'100%',height:'auto',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <img src={name=='Government ID (front)/بطاقة الهوية الوطنية-أمامية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/id+from+front.jpg':
                    name=='Government ID (back)/بطاقة الهوية الوطنية-خلفية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/id+from+back.jpg':
                    name=='Shopper photo/صورة شخصية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/profile+face.jpg':
                    name=='Commercial driver license (front)/رخصة القيادة التجارية-أمامية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B1%D8%AE%D8%B5%D8%A9+%D8%B3%D9%88%D9%82+%D9%85%D9%86+%D8%A7%D9%84%D8%A7%D9%85%D8%A7%D9%85.jpg':
                    name=='Commercial driver license (back)/رخصة القيادة التجارية-خلفية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B1%D8%AE%D8%B5%D8%A9+%D8%B3%D9%88%D9%82+%D9%85%D9%86+%D8%A7%D9%84%D8%AE%D9%84%D9%81.jpg':
                    name=='Judicial record/سجل عدلي'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D9%84%D8%A7+%D8%AD%D9%83%D9%85+%D8%B9%D9%84%D9%8A%D9%87.jpg':
                    name=='Compulsory insurance/تأمين الزامي'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B9%D9%82%D8%AF+%D8%A7%D9%84%D8%B6%D9%85%D8%A7%D9%86+%D8%A7%D9%84%D8%A7%D9%84%D8%B2%D8%A7%D9%85%D9%8A.jpg':
                    name=='Car registration (front)/رخصة السيارة - أمامية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B1%D8%AE%D8%B5%D8%A9+%D8%B3%D9%8A%D8%B1+%D9%85%D8%B1%D9%83%D8%A8%D8%A9+%D8%A7%D9%84%D9%8A%D8%A9+%D9%85%D9%86+%D8%A7%D9%84%D8%A7%D9%85%D8%A7%D9%85.jpg':
                    name=='Car registration (back)/رخصة السيارة - خلفية'?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B1%D8%AE%D8%B5%D8%A9+%D8%B3%D9%8A%D8%B1+%D9%85%D8%B1%D9%83%D8%A8%D8%A9+%D8%A7%D9%84%D9%8A%D8%A9+%D9%85%D9%86+%D8%A7%D9%84%D8%AE%D9%84%D9%81.jpg':
                    name=='Car permit/وكالة قيادة السيارة'?`https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/carpermit.png`:

                    null} style={{width:200,height:200,objectFit:'contain'}}/>
                </div>
                        <input {...getInputProps()} />
                        <div style={{width:'100%',height:50,backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center',boxSizing:'border-box',boxShadow:'0px 4px 16px rgba(0,0,0,0.1)'}}>
                               {uploaded && !uploaded[key] ?
                        <Button disabled={ (applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3))}  style={{width:'90%',height:40,backgroundColor:'black',border:'none'}} >Upload</Button> 
                        :
                        <Button disabled={applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3)} variant='danger' onClick={()=> setModal({delete: true, show: true})} style={{width:'90%',height:40,backgroundColor:'black',border:'none'}}>Delete your upload</Button>
                        }
                        </div>
                        {/* <div className='d-flex justify-content-center align-items-center p-5 mt-4' style={{backgroundColor: '#edf2f7',width:'50%', border: '2px dashed #e05b0e'}}>
                            <div className='d-flex flex-column'>
                                <Button className='tt-button'>Upload</Button>
                                <p className='m-0 text-center'>or drag and drop your file here</p>
                                <p className='m-0 text-center'>Maximum file size of 4 MB</p>
                            </div>
                        </div>  */}
                    </div>
                    </div>
                    :
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>

                          <div className='mt-3'>
                        {file.type.includes('image') ? 
                        <div className='border p-1' style={{position: 'relative', width: 'fit-content'}}>
                            <img onClick={()=>setFile(null)} src={Xmark} alt='x-mark' style={{width: 25, height: 25, objectFit:'contain', position:'absolute', top: -11, right: -9, cursor: 'pointer'}} />
                            <img src={URL.createObjectURL(file)} alt='uploaded-img' style={{width: 200, height: 200, objectFit:'contain'}} />
                        </div>
                        :
                        <div className='d-flex' style={{width: 'fit-content'}} >
                            <div style={{position:'relative', width: 'fit-content'}}>
                                <img onClick={()=>setFile(null)} src={Xmark} alt='x-mark' style={{width: 25, height: 25, objectFit:'contain', position:'absolute', top: -11, right: -9, cursor: 'pointer'}} />
                                <img src={PdfIcon} alt='pdf-icon' style={{width: 80, height: 80, objectFit:'contain'}} />
                            </div>
                            <p className='ms-3'>{file.path}</p>
                        </div>
                        }
                    </div> 
                    <div style={{width:'100%',height:50,marginTop:'5%',backgroundColor:'white',display:'flex',justifyContent:'center',alignItems:'center',boxSizing:'border-box',boxShadow:'0px 4px 16px rgba(0,0,0,0.1)'}}>
                               {uploaded && !uploaded[key] ?
                        <Button disabled={!file || (applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3))} onClick={()=> setModal({delete: false, show: true})} style={{width:'90%',height:40,backgroundColor:'black',border:'none'}} >Submit</Button> 
                        :
                        <Button disabled={applicationStatus != null && (applicationStatus != 2 && applicationStatus != 3)} variant='danger' onClick={()=> setModal({delete: true, show: true})} style={{width:'90%',height:40,backgroundColor:'black',border:'none'}}>Delete your upload</Button>
                        }
                        </div>
                        </div>
                
                 
                    }

                     

</div>
</div>
  )
}

export default Upload