import React from 'react'
import logo from "../shopperr.png";
import google from "../google.png";
import apple from "../apple.png";
import { useNavigate } from 'react-router-dom';
export default function PreRegister() {
    const navigate = useNavigate()
  return (
    <div style={{marginTop:'5%',marginBottom:'1%' }}>

<img src={logo} alt='trakitrak shoppers logo'  style={{height:55,width:370, objectFit:'contain' }} />

<div style={{width:'100%',marginBottom:'5%',paddingLeft:'3%',alignSelf:'center',flexDirection:'column',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e05b0e'}}>  
<h1 style={{color:'white',paddingBottom:0,paddingTop:15}}>
    Earn with the best
</h1>
<h4 style={{color:'white',paddingBottom:15,fontSize:16,textAlign:'center'}}>Deliver with TrakiTrak and get more opportunities to earn.</h4>
<div className='whitediv'>
<div onClick={()=>navigate('/register')} className='divv' style={{boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px',borderRadius:10,backgroundColor:'#EEEEEE',justifyContent:'center',alignItems:'center',display:'flex'}
}>
<p className='continue'>Continue With Your Email</p>
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50%',marginTop:10}}>
<div style={{width:'35%',height:1,backgroundColor:'#afafaf'}}/>
<p style={{margin:0,color:'#afafaf',marginRight:10,fontSize:14,marginLeft:10}}>Or</p>
<div style={{width:'35%',height:1,backgroundColor:'#afafaf'}}/>
</div>
<div onClick={()=>navigate('/register-google')} className='divv' style={{height:48,cursor:'pointer',marginTop:10,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px',borderRadius:10,backgroundColor:'#EEEEEE',justifyContent:'center',alignItems:'center',display:'flex'}}>
    <img src={google} alt='trakitrak shoppers logo' className='googlelogo'   />
    <p className='continue'>Continue With Google</p>
</div>
<div onClick={()=>navigate('/register-apple')} className='divv' style={{height:48,cursor:'pointer',marginTop:10,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px',borderRadius:10,backgroundColor:'#EEEEEE',justifyContent:'center',alignItems:'center',display:'flex'}}>
    <img src={apple} alt='trakitrak shoppers logo' className='googlelogo'   />
    <p className='continue'>Continue With Apple</p>
</div>

</div>
</div>


</div>
  )
}
