import React, {useState, useEffect} from 'react';
import { Container, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { uri } from '../utils';
import PhoneInput, {isValidPhoneNumber, isPossiblePhoneNumber} from 'react-phone-number-input';
import AppleSignin from 'react-apple-signin-auth';
import logo from "../shopperr.png";

export default function Registerapple() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [cityid, setCityid] = useState('');
    const [isGoogle, setIsGoole] = useState(false);
    const [cities, setCities] = useState([]);
    const [transport, setTransport] = useState('moto');
    const navigate = useNavigate();

    const getCities = async () => {
      try{
        const req = await fetch(uri('/cities/dropdown'));
        const res = await req.json();
        if(req.status == 200){
          setCities(res);
          if(res.length > 0) setCityid(res[0].id)
        }
      }catch(e){

      }
    }

    const register = async (e) => {
        try{
          e.preventDefault()
            if(!email.trim() || !password.trim() || !password2.trim() || password !== password2 || !phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid) return;
            e.preventDefault();
            if(error) setError(null)
            setLoading(true);
            const req = await fetch(uri('/register'), {
                method: 'POST',
                body: JSON.stringify({email, password, phone: `${phone}`, name, transport,cityid}),
                headers: {'Content-Type': 'application/json'}
            });
            const res = await req.json();
            if(req.status === 200){
                localStorage.setItem('token', res.access_token)
                navigate(!res.verified ? "/otp" : "/profile", {replace:true});
            } 
            else if(req.status == 401){
              localStorage.setItem("token", res.access_token);
              navigate('/otp')
            } 
            else {
                setError(res.message);
                setLoading(false);
            }
        } catch(err) {
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }
    }

    const AppleSignIn = async (id_token,firstName,lastName) => {
        try{
            if(error) setError(null)
            setLoading(true);
            const req = await fetch(uri('/sociallogin'), {
                method: 'POST',
                body: JSON.stringify({id_token, transport, phone: `${phone}`, cityid,is_google:false,is_apple:true,firstName:firstName?firstName:null,lastName:lastName?lastName:null}),
                headers: {'Content-Type': 'application/json'}
            });
            const res = await req.json();
            if(req.status === 200){
                localStorage.setItem('token', res.access_token)
                navigate("/profile", {replace:true});
            } else {
                setError(res.message);
                setLoading(false);
            }
        } catch(err) {
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }
      }

    useEffect(() => {
      getCities()
    },[])

  if(loading) return <Spinner />
  return (
    <div style={{marginTop:'5%',marginBottom:'1%' }}>
                <div style={{width:'100%',marginBottom:'5%',paddingLeft:'3%',alignSelf:'center',flexDirection:'column',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e05b0e'}}>  
                <h1 style={{color:'white',fontFamily: 'Poppins',paddingBottom:15,paddingTop:15}}>Trakitrak Shopper</h1>
                <div style={{zIndex:10,padding:16,marginBottom:-16,color:'rgb(25, 25, 25)',backgroundColor:'white',borderRadius:8,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px',minWidth:'50%'}}>

        <Form.Group className='mt-3'>
          <Form.Label>City</Form.Label>
          <Form.Select onChange={e => setCityid(e.target.value)} value={cityid}>
            {cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone ? phone : ''}
            defaultCountry='LB'
            initialValueFormat='national'
            className='form-control'
            onChange={setPhone}
          />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Transportation Type</Form.Label>
          <div>
            <input type='checkbox' checked={transport === 'moto'} onChange={(e) => setTransport(e.target.checked ? 'moto' : null)} style={{marginRight: 5}} />
            <Form.Label>Moto</Form.Label>
          </div>
          <div>
            <input type='checkbox' checked={transport === 'car'} onChange={(e) => setTransport(e.target.checked ? 'car' : null)} style={{marginRight: 5}} />
            <Form.Label>Car</Form.Label>
          </div>
        </Form.Group>
        <div className={!phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid || !transport ? `google-btn-disabled` : 'google-btn'} style={{margin: '20px 0px 30px 0px'}}>
          <div style={{width: '75%'}}>
          <AppleSignin
          
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: 'co.trakitrak.drivertrakitrak.client',
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email name',
            
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: 'https://shoppers.trakitrak.com/login',
            /** State string that is returned with the apple response */
            state: '',
            /** Nonce */
            nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            usePopup: true,
          }} 
          // REQUIRED
          /** General props */
          uiType="dark"
          /** className */
          className="apple-auth-btn"
          /** Removes default style tag */
          noDefaultStyle={false}
          /** Allows to change the button's children, eg: for changing the button text */
          buttonExtraChildren="Continue with Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={(response) => {
            AppleSignIn(response.authorization.id_token,response?.user?.name?.firstName,response?.user?.name?.lastName)

          }} // default = undefined
          /** Called upon signin error */
          onError={(error) => console.error(error)} // default = undefined
          /** Skips loading the apple script if true */
          skipScript={false} // default = undefined
          /** Apple image props */
          iconProp={{ style: { marginTop: '10px' } }} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
         // render={(props) => <button {...props}>My Custom Button</button>}
        />
            </div>
         
        </div>
        <p style={{marginTop:'2%'}}>Already have an account? <Link style={{color:'#e05b0e',textDecoration:'underline'}} to={'/login'}>Login</Link></p>
</div>
</div>
    </div>
  )
}
