import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom"
import Login from "./pages/Login";
import AuthedRoute from "./components/AuthedRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import Upload from "./pages/Upload";
import Details from "./pages/Details";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPassword from "./pages/ResetPassword";
import PreRegister from "./pages/PreRegister";
import Registergoogle from "./pages/Registergoogle";
import Registerapple from "./pages/Registerapple";

function App() {
  return (
    <GoogleOAuthProvider clientId="367005268436-794dmep5sgft2rio0ghop1q20b2ftllg.apps.googleusercontent.com">
      <Router>
          <Routes>
            <Route path="/login" element={<AuthedRoute component={Login} />}></Route>
            <Route path="/preregister" element={<AuthedRoute component={PreRegister} />}></Route>
            <Route path="/register" element={<AuthedRoute component={Register} />}></Route>
            <Route path="/register-google" element={<AuthedRoute component={Registergoogle} />}></Route>
            <Route path="/register-apple" element={<AuthedRoute component={Registerapple} />}></Route>

            <Route path="/reset-password" element={<AuthedRoute component={ResetPassword} />}></Route>

            <Route path="/profile" element={<ProtectedRoute component={Profile} />}></Route>
            <Route path="/profile/details" element={<ProtectedRoute component={Details} />}></Route>
            <Route path="/profile/:key" element={<ProtectedRoute component={Upload} />}></Route>
            {/* <Route path="/otp" element={<AuthedRoute component={Otp} />}></Route> */}

            <Route path="/otp" element={<ProtectedRoute component={Otp} />}></Route>
            <Route path="*" element={<Navigate to="/login" />}></Route>
          </Routes>
          <ToastContainer />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
