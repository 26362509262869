import React, {useState, useEffect, useMemo} from 'react'
import { useNavigate } from 'react-router-dom'
import {Button, Alert, Modal } from 'react-bootstrap';
import ProfileItem from '../components/ProfileItem';
import Spinner from '../components/Spinner';
import { showToast, uri } from '../utils';
import rightIcon from "../next.png"
import Xmark from "../x-mark.png";
import Correct from "../accept.png";

const data_fields = ['first_name', 'last_name', 'phone_number', 'cityid', 
'emergency_phone', 'emergency_name', 'bankid', 'account_type', 'account_number', 
'mtid', 'mt_number', 'dob'];

let files_fields = [
  {key: 'govid_front', label: 'Government ID (front)/بطاقة الهوية الوطنية-أمامية'}, 
  {key: 'govid_back', label: 'Government ID (back)/بطاقة الهوية الوطنية-خلفية'}, 
  {key: 'img', label: 'Shopper photo/صورة شخصية'}, 
  {key:  'commercial_driver_license_front', label: 'Commercial driver license (front)/رخصة القيادة التجارية-أمامية'}, 
  {key: 'commercial_driver_license_back', label: 'Commercial driver license (back)/رخصة القيادة التجارية-خلفية'}, 
  {key: 'judicial_record', label: 'Judicial record/سجل عدلي'}, 
  {key: 'car_permit', label: 'Car Permit/وكالة قيادة السيارة'}, 
  {key: 'car_registration_front', label: 'Car registration (front)/رخصة السيارة - أمامية'}, 
  {key: 'car_registration_back', label: 'Car registration (back)/رخصة السيارة - خلفية'}, 
  {key: 'compulsory_insurance', label: 'Compulsory insurance/تأمين الزامي'}
];


const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore(!showMore);
  const cantSubmit = useMemo(() => {
      return (!data) || (data && data.user.application != null && data.user.application.status != 2 && data.user.application.status != 3)
  }, [data]);

  
  // const dataAccepted = useMemo(()=> {
  //   return data && data.user.datax && data_fields.every(field => {
  //     return data.user.datax[field] && data.user.datax.statusx[`${field}_conf`] == 1;
  //   })
  // },[data]);

  const dataCompleted = useMemo(() => {
    let mydata = data && data.user.datax;
    //console.log(mydata)
    return data && data.user.datax && mydata.first_name && mydata.last_name && mydata.phone_number && mydata.cityid && mydata.dob && ((mydata.bankid && mydata.account_type && mydata.account_number) || (mydata.mtid && mydata.mt_number)) ? true : false
  },[data]);

  //console.log({dataCompleted})

  const dataAccepted = useMemo(()=> {
    return data && data.user.datax && data_fields.every(field => {
      return data.user.datax.statusx[`${field}_conf`] == 1;
    })
  },[data]);

  const dataHasOneRejection = useMemo(()=> {
    return data && data.user.datax && data_fields.some(field => {
      return data.user.datax.statusx[`${field}_conf`] == 2;
    })
  },[data]);

  let application_status = useMemo(() => {
    return data && data.user && data.user.application != null && data.user.application.status;
  },[data]);
  
  const logout = () => {
    localStorage.removeItem('token');
    navigate("/login", {replace: true});
  }

  if(!application_status && application_status !== 0) application_status = null;

  const getProfile = async () => {
    try{
      const req = await fetch(uri('/profile'), {method: 'GET',headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}});
      const res = await req.json();
      if(req.status === 200){
        //console.log(res);
        setData(res);
        if(res.user.profiled.drivertyp == 'moto'){
          files_fields = files_fields.map((field) => {
            if(field.key.includes('car_registration')){
              if(field.key == 'car_registration_front') return {...field, label: 'Moto registration (front)/رخصة الدراجة - (أمامية)'}
              else return {...field, label: 'Moto registration (back)/رخصة الدراجة - (خلفية)'}
            } else return field;
          })
        }
        //if(res.profile && res.profile.application && res.profile.application.status == 0) setError("Your application is being reviewed by our team")
        setLoading(false);
      } else if(req.status === 401) {
        navigate('/otp', {replace: true});
      } else if(req.status === 498) {
        localStorage.removeItem("token");
        navigate('/login', {replace: true});
      } else {
        setError(res.message);
        setLoading(false);
      }
    } catch (err) {
      setError("Server Error");
      setLoading(false);
    }
  }

  const submitApplication = async () => {
    try{
      setLoading(true);
      const req = await fetch(uri('/submitapplication'), {
        method: 'POST',
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
        body: JSON.stringify({})
      });
      const res = await req.json();
      if(req.status === 200){
        showToast('Application Submitted', 'success');
        setModal(false)
        setRefresh(refresh+1);
      } else if(req.status === 401) {
        navigate('/otp', {replace: true});
      } else if(req.status === 498) {
        localStorage.removeItem("token");
        navigate('/login', {replace: true});
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError("Server Error");
      setLoading(false);
    }
  }

  useEffect(() => {
    getProfile();
  },[refresh]);

  if(loading) return <Spinner />
  return (
    <div style={{backgroundColor: `white`,flexDirection:'column',width:'100%',alignItems:'center',height:700,display:'flex',alignSelf:'center'}}>

    <div className='home-container'>

        <Modal
            show={modal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={e => setModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to submit?</p>
            </Modal.Body>
        <Modal.Footer>
            <Button variant='danger' onClick={() => setModal(false)}>Close</Button>
            <Button onClick={submitApplication}>Confirm</Button>
      </Modal.Footer>
    </Modal>

<div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>

<h3 className='mt-4 mb-4'style={{margin:0,marginLeft:15}} >Hi {data && data.user.name} 👋</h3>
      <Button  style={{marginTop:20,marginRight:10}}onClick={logout} variant='danger' className='mb-4'>Logout</Button>


</div>

      {application_status != null && application_status != 3 ? (
        <Alert className='mt-3' variant={application_status == 0?'secondary': application_status == 1? 'dark' : 'danger'}>
          <p className='m-0'>
       
          {application_status == 0 ? 'Your application is pending and being reviewed by our team./ تم تقديم طلبك وهو قيد المراجعة من قبل فريقنا.' : 
          application_status == 2 ? 'Your application has been rejected for the following reasons:/ تم رفض طلبك للأسباب التالية:' :
          application_status == 1 ? (
            <div>
            <p className='m-0' style={{textAlign:'right'}}>✨ {data && data.user.name} تهانينا !</p>
            <p  className='m-0' style={{textAlign:'right'}}>يسعدنا أن نخبرك أن طلبك للعمل كسائق على منصتنا قد تم الموافقة عليه.</p>
            
            {showMore && (
              <>
                <p style={{textAlign:'right'}}>لقد تمّت مراجعة جميع مستنداتك وبياناتك بدقة، ونحن على ثقة من أنك ستكون إضافة رائعة لفريقنا.</p>
                <p style={{textAlign:'right'}}>أنت الآن جاهز لبدء العمل كسائق على منصتنا. يمكنك البدء بتلقي طلبات العملاء وكسب الدخل من خلال تقديم خدمة ممتازة لعملائنا.</p>
                <p style={{textAlign:'right'}}>نتمنى لك كل التوفيق والنجاح في رحلتك معنا.</p>
                <p style={{textAlign:'right'}}>نصائح لبدء العمل:</p>
                <ul style={{textAlign:'right'}}>
                  <li style={{textAlign:'right'}}>تأكد من تحديث تطبيقنا بشكل دائم.</li>
                  <li style={{textAlign:'right'}}>قم بتشغيل GPS هاتفك لكي تتمكن من تلقي طلبات الرحلات.</li>
                  <li style={{textAlign:'right'}}>حافظ على سيارتك نظيفة ومرتبة.</li>
                  <li style={{textAlign:'right'}}>كن مهذبًا وودودًا مع العملاء.</li>
                  <li style={{textAlign:'right'}}>اتبع جميع قواعد السلامة والمرور.</li>
                </ul>
                <p style={{textAlign:'right'}}>لمزيد من المعلومات، يرجى زيارة:</p>
                <div style={{justifyContent:'flex-end',alignItems:'center',width:'100%',alignSelf:'flex-start',display:'flex'}}>
                <a style={{textAlign:'right'}} className='m-0' href='//www.trakitrak.com'>TrakiTrak.com:</a>

                    <p  style={{textAlign:'right'}}className='m-0'>موقعنا الإلكتروني</p>
                </div>
                <div style={{justifyContent:'flex-end',alignItems:'center',width:'100%',alignSelf:'flex-start',display:'flex'}}>
                   
                <a onClick={()=>{window.Intercom('show')}}  style={{textAlign:'right',cursor:'pointer'}}className='m-0' >Help Center:</a>
                 <p  style={{textAlign:'right'}}className='m-0'> مركز المساعدة</p>
                    
                </div>
                

                <p style={{textAlign:'right'}}>شكرًا لك على انضمامك إلى فريقنا!</p>
                <p style={{textAlign:'right'}}>مع أطيب التحيات,</p>
                <p style={{textAlign:'right'}}>TrakiTrak Team</p>
              </>
            )}
      
            <div className='m-0' style={{cursor:'pointer'}} onClick={toggleShowMore}>
             <p className='m-0'>{showMore ? 'View Less' : 'View More'}</p>
            </div>
          </div>
          ) 
          :null
          }
          {application_status == 2 && 
          <p className='m-0' dangerouslySetInnerHTML={{__html:data.user.application.reason}} style={{marginTop:'1.5%',fontSize:18,color:'#545454'}}></p>

          }
            
          </p>
        </Alert>
      ) : null}

      {error && <Alert className='mt-3' variant='warning'>{error}</Alert>}
      <p style={{fontSize:22,fontFamily:'Poppins-SemiBold',marginLeft:5}}>Personal information:</p>
      <div onClick={()=>navigate(`/profile/details`)} className='mb-4 border p-3 rounded profile-item' 
      style={{
        opacity:application_status == 0 ? 0.5 : 1,
        display: 'flex',
        justifyContent:'space-between', 
        alignItems:'center',
        //pointerEvents: cantSubmit ? 'none': 'all'
      }}
      >
              <div style={{ display: 'flex', flexDirection: 'column', }}>
              <h6  className='m-0'>Personal Information/معلومات شخصية</h6>
  {!dataAccepted && !dataHasOneRejection ? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Pending</p>:
dataAccepted? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Approved</p>:
dataHasOneRejection? <p style={{ fontSize: 14, color: 'gray',margin:0 }}>Rejected</p>:
null}
 
</div>
 
        <div style={{display:'flex',alignItems:'center'}}>
          {dataAccepted || dataHasOneRejection ? null :
              <p style={{margin:0, color:application_status == 0? 'gray' : !dataHasOneRejection && dataCompleted ? 'green' : 'red' }}>{application_status == 0 ?'In Review': !dataAccepted && !dataHasOneRejection && dataCompleted ?'Completed':'Incomplete'}</p>
          }

        {dataHasOneRejection ?
          <img alt='right-arrow-icon' src={dataHasOneRejection ? Xmark : dataAccepted ? Correct : rightIcon} style={{width: 20, height:20}}></img>
          :
          dataAccepted ?
<img alt='right-arrow-icon' src={dataHasOneRejection ? Xmark : dataAccepted ? Correct : rightIcon} style={{width: 20, height:20}}></img>

:
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"><title>Chevron right small</title><path d="m16.9 12-4.6 6H8.5l4.6-6-4.6-6h3.8l4.6 6Z" fill="currentColor"></path></svg>

                      }
        </div>
      </div>
      <p style={{fontSize:22,fontFamily:'Poppins-SemiBold',marginLeft:5}}>Documents:</p>
      {/* {data && data.items.map((x, i) => <ProfileItem application_status={application_status} item={x} key={x.id} />)} */}
      {data && data.user && data.user.datax.statusx && files_fields.map(x => <ProfileItem conf={data.user.datax.statusx[`${x.key}_conf`]} keyx={x.key} label={x.label} application_status={application_status} item={data.user.datax[`${x.key}`]} key={x.key} /> )}
      {/* was here */}
      <Button disabled={cantSubmit} onClick={()=>setModal(true)} style={{width:'100%'}} className='tt-button mb-5'>Submit</Button>
    </div>
    </div>
  )
}

export default Profile